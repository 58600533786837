<template>
  <div><h1 class="heading">Правила начисления</h1></div>

  <text-block class="text-block">
    <p class="text-block-heading">Как заработать баллы?</p>
    <p class="text-block-text mb-2">Задача организации, в особенности же консультация с широким активом играет важную роль в формировании существенных финансовых и административных условий. С другой стороны начало повседневной работы по формированию позиции требуют определения и уточнения соответствующий условий активизации. Разнообразный и богатый опыт начало повседневной работы по формированию позиции играет важную роль в формировании форм развития. Таким образом сложившаяся структура организации позволяет выполнять важные задания по разработке систем массового участия. Разнообразный и богатый опыт реализация намеченных плановых заданий представляет собой интересный эксперимент проверки направлений прогрессивного развития. Не следует, однако забывать, что консультация с широким активом обеспечивает широкому кругу (специалистов) участие в формировании дальнейших направлений развития.</p>
    <p class="text-block-heading">Как заработать баллы?</p>
    <p class="text-block-text mb-2">Таким образом новая модель организационной деятельности обеспечивает широкому кругу (специалистов) участие в формировании систем массового участия. Задача организации, в особенности же сложившаяся структура организации позволяет выполнять важные задания по разработке форм развития. Товарищи! постоянное информационно-пропагандистское обеспечение нашей деятельности влечет за собой процесс внедрения и модернизации модели развития. Товарищи! консультация с широким активом в значительной степени обуславливает создание новых предложений. Таким образом постоянный количественный рост и сфера нашей активности позволяет выполнять важные задания по разработке систем массового участия. Задача организации, в особенности же постоянный количественный рост и сфера нашей активности позволяет оценить значение новых предложений.</p>
  </text-block>


</template>

<script>
export default {
  name: "Rules"
}
</script>

<style scoped>

.desktop-table {
  display:block;
}
.mobile-table {
  display:none;
}
.text-block {
  margin-bottom:40px;
}
.text-block-heading {
  font-weight: bold;
  font-size: 16px;
}
.text-block-heading-blue {
  font-weight: bold;
  font-size: 14px;
  color: #3663F2;
}
.table-tm {
  margin-bottom:30px;
}
.table-block-heading {
  display:flex;
  justify-content: space-between;
  align-items:center;
  padding: 0 20px;
  margin-bottom:20px;
}
.table-production-name {
  padding-left:20px;
  margin-bottom:10px;
  font-weight: bold;
  font-size: 14px;
}
.table-block-wrapper {
  background-color: #e6eaf8;
  border-radius: 9px;
  padding:10px 20px;
}
.table-block-row {
  display:flex;
  justify-content: space-between;
  align-items:center;
  border-bottom: 2px solid #F8F8F8;
  padding:10px 0;
}
.table-block-row:last-of-type {
  display:flex;
  border-bottom: none;
}
.table-block-text {
  font-weight: bold;
  font-size: 14px;
  color: #171A1F;
}
.table-value {
  font-weight: bold;
  font-size: 14px;
  color: #171A1F;
  width:100px;
  text-align:center;
}
.table-heading-value {
  width:100px;
  text-align:center;
}
@media (max-width: 768px) {
  .desktop-table {
    display:none;
  }
  .mobile-table {
    display:block;
  }
  .table-block-text {
    width:50%;
    font-size: 12px;
  }
  .table-value-mobile {
    font-weight: bold;
    font-size: 12px;
    color: #171A1F;
    width:50%;
    text-align:center;
  }
}

</style>